import React, { Component } from "react";
import { translations } from "../../../../translate";
import { Col, Row, Progress } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import PublishIcon from "@material-ui/icons/Publish";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

export default class SendPayRequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitError: "",
      loading: 0,
      errMessage: "",
      billType: "",
      amount: 1,
      billTypes: [],
      supervisorsList: [],
      supervisorPos: null,
    };
  }
  getBillsType() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getBillsType",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              billTypes: data["billsType"],
            });
          }
        }
      });
  }
  componentDidMount() {
    this.getSuperVisors();
  }
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
    });
  };
  setUpSelectOptions = (data, type) => {
    if (type == "currency") {
      return (
        <option value={data.id} key={data.id}>
          {data.currency}
        </option>
      );
    } else if (type == "timeZone") {
      return (
        <option value={data.id} key={data.id}>
          {data.name}
        </option>
      );
    } else if (type == "dists") {
      return (
        <option value={data.userId} key={data.id}>
          {data.fname}
        </option>
      );
    }
  };
  handleChangeSelect = (type, option) => {
    console.log(option);
    this.setState(
      (state) => {
        return {
          [type]: option,
        };
      },
      () => {}
    );
  };
  getSuperVisors() {
    if (localStorage.getItem("USER_LEVEL") == 1) {
      if (this.state.distId?.length == 0) return;

      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getDistPos",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          getType: "all",
          accountType: 17,
          distId: this.state.distId
            ? this.state.distId.map((e) => e.userId)
            : "",
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState(
                {
                  supervisorsList: data["data"],
                },
                function() {}
              );
            }
          }
        });
    } else {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getPerm",
          permType: 8,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          this.setState({ supervisorsList: data });
        });
    }
  }
  handleValidSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    const file = document.querySelector("#posImageFile")?.files?.[0];
    if (localStorage.getItem("USER_LEVEL") == 8 && !this.state.supervisorPos) {
      this.setState({ posError: 1 });
      return;
    } else if (localStorage.getItem("USER_LEVEL") == 8) {
      data.append("receiverId", this.state.supervisorPos?.permId);
    }
    this.setState({ posError: 0 });

    if (this.state.amount < 1) return;
    if (!file) {
      this.setState({ submitError: "يرجى ارفاق صورة" });
      return;
    }
    this.setState({ loadingSave: 1, submitError: "" });
    data.append("type", "sendPayRequest");
    data.append("userId", localStorage.getItem("USER_ID"));
    data.append("token", localStorage.getItem("USER_TOKEN"));
    data.append("lang", localStorage.getItem("LANG"));
    data.append("amount", this.state.amount);
    data.append("sendPayRequestImage", file);
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .catch((err) => console.log(err, "1"))
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] != 0 || data["error"]) {
            this.setState({ submitError: data["errorMsg"], loadingSave: 0 });
          } else {
            this.setState({ loadingSave: 0 });
            this.props.close();
          }
        }
      })
      .catch((err) => console.log(err, "2"));

    this.setState({ loadingSave: 0 });
  };
  remove = (event) => {
    event.target.value = null;
  };
  handleImgChange = (event) => {
    var fileReader = new FileReader();
    const scope = this;

    fileReader.readAsBinaryString(event.target.files[0]);
    fileReader.onprogress = function(data) {
      if (data.lengthComputable) {
        var progress = parseInt((data.loaded / data.total) * 100, 10);
        scope.setState({ progressUploadHeader: progress });
      }
    };

    this.setState({
      [event.target.name]: event.target.files[0]["name"],
    });
  };
  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {localStorage.getItem("USER_LEVEL") == 8
                    ? Strings.tranDebit
                    :Strings["pay"]}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.close}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>
        <div className="sidebar-body-view">
          {this.state.loading == 1 && (
            <Row>
              <Col md={12} style={{ marginTop: "15%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}
          {this.state.loading != 1 && (
            <AvForm onSubmit={this.handleValidSubmit}>
              {/* <AvField
                type="select"
                name="billType"
                required
                errorMessage={Strings["This field is required"]}
                label={Strings["Invoice Type"] + " *"}
                value={this.state.billType}
                onChange={this.handleInputChange}
                className="p-1 pl-3 mt-2"
              >
                <option
                  style={{
                    display: "none",
                  }}
                  value=""
                  selected="selected"
                >
                  {Strings["Invoice Type"]}
                </option>
                {this.state.billTypes?.map((data, index) => (
                  <option value={data?.key} key={index}>
                    {data?.value}
                  </option>
                ))}
              </AvField> */}
              {localStorage.getItem("USER_LEVEL") == 8 && (
                <div className="member-searchable-list mb-2">
                  <label
                    className={this.state.posError == 1 ? "label-error" : ""}
                  >
                    {Strings["receiverName"]} *
                  </label>
                  {/* className={this.state.msgError == 2 ? 'label-error' : ''} */}
                  <Picky
                    value={this.state.supervisorPos}
                    options={this.state.supervisorsList}
                    onChange={this.handleChangeSelect.bind(
                      this,
                      "supervisorPos"
                    )}
                    open={false}
                    valueKey="permId"
                    labelKey={"name"}
                    multiple={false}
                    includeSelectAll={true}
                    includeFilter={true}
                    dropdownHeight={200}
                    placeholder={Strings["POS Supervisor Name"]}
                    filterPlaceholder={Strings["Select POS Supervisor"]}
                    className={
                      this.state.posError == 1 ? "border border-danger" : ""
                    }
                  />
                </div>
              )}
              <AvField
                name="amount"
                type="number"
                label={Strings["Amount"]}
                placeholder={Strings["Amount"]}
                value={this.state.amount}
                onChange={this.handleInputChange}
                errorMessage={Strings["This field is required"]}
                required
                min="1"
              />
              <div className="mt-2 mb-2">
                <label>{Strings["Image"]}</label>
                <span className={"fileInput-span col-md-12"}>
                  <PublishIcon />{" "}
                  <span className="pointer-cursor pt-3">
                    {Strings["Image"]}
                  </span>
                  <input
                    multiple
                    onClick={(event) => this.remove(event)}
                    type="file"
                    accept="image/jpeg, image/png, image/gif"
                    className="fileInput-width fileInput-opacity"
                    onChange={(e) => this.handleImgChange(e)}
                    name="cardCodeFile"
                    id="posImageFile"
                  ></input>
                </span>
                {this.state.progressUploadHeader != undefined &&
                  this.state.progressUploadHeader > 0 && (
                    <Progress
                      striped
                      value={this.state.progressUploadHeader}
                      className="mb-1 mt-1 progress-bg-color"
                    >
                      {this.state.progressUploadHeader} %
                    </Progress>
                  )}
                {this.state.displayErrorHeaderMsg == true && (
                  <div className="invalid-feedback d-block mb-1">
                    {Strings["Upload Excel File only"]}
                  </div>
                )}
                {this.state.fileUploadError && (
                  <p className="invalid-feedback d-block mb-1">
                    {Strings["This field is required"]}
                  </p>
                )}
              </div>
              {this.state.loadingSave == 1 ? (
                <button
                  type="button"
                  className="btn form-save-btn mr-1 ml-1 task-action-btn-loading"
                >
                  <CircularProgress className="SaveCircularProgressColor" />{" "}
                  {Strings["Save"]}
                </button>
              ) : (
                <button type="submit" className="btn form-save-btn mr-1 ml-1">
                  {" "}
                  {Strings["Save"]}{" "}
                </button>
              )}
              {this.state.submitError && (
                <div className="error-text-black mt-3">
                  <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                  {this.state.submitError}
                </div>
              )}
            </AvForm>
          )}
        </div>
      </div>
    );
  }
}
