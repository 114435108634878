window.TEST_MODE = "";
if (
  window.location.hostname == "localhost" ||
  window.location.hostname == "dev44.hoja-crm.com" ||
  window.location.hostname == "dev.easypay-card.com" ||
  window.location.hostname == "dev45.hoja-crm.com"
) {
  // window.HTTP_ROOT_PATH = 'https://dev45.hoja-crm.com/API/';
  window.HTTP_ROOT_PATH = "https://cp82.easypay-card.com/API/";
  // window.HTTP_ROOT_PATH = "https://users.gm-cards.com/API/";

  // window.HTTP_ROOT_PATH = 'https://cp82.easypay-card.com/API/';
  // window.HTTP_ROOT_PATH = 'https://stg.easypay-card.com/API/';
  window.DOMAIN_TYPE = "1";
  window.TEST_MODE = "1";
} else if (window.location.hostname == "users.gm-cards.com") {
  window.HTTP_ROOT_PATH = "https://cp-gm.gm-cards.com/API/";
  window.DOMAIN_TYPE = "9";
} else if (window.location.hostname == "demouser.easypay-card.com") {
  window.HTTP_ROOT_PATH = "https://demo.easypay-card.com/API/";
  window.DOMAIN_TYPE = "5";
} else if (window.location.hostname == "dev43.hoja-crm.com") {
  // window.HTTP_ROOT_PATH = 'https://cp8.easypay-card.com/API/';
  window.HTTP_ROOT_PATH = "https://cp82.easypay-card.com/API/";

  window.DOMAIN_TYPE = "1";
} else if (window.location.hostname == "lyuser.easypay-card.com") {
  window.HTTP_ROOT_PATH = "https://ly.easypay-card.com/API/";
  window.DOMAIN_TYPE = "7";
} else if (window.location.hostname == "esportuser.easypay-card.com") {
  window.HTTP_ROOT_PATH = "https://esport.easypay-card.com/API/";
  window.DOMAIN_TYPE = "8";
} else if (window.location.hostname == "control.elite-card.net") {
  window.HTTP_ROOT_PATH = "https://elite-card.net/API/";
  window.DOMAIN_TYPE = "6";
} else if (window.location.hostname == "control.amg-card.com") {
  window.HTTP_ROOT_PATH = "https://amg-card.com/API/";
  window.DOMAIN_TYPE = "3";
} else {
  // window.HTTP_ROOT_PATH = 'https://beta.easypay-card.com/API/'
  window.HTTP_ROOT_PATH = "https://cp.easypay-card.com/API/";
  // window.HTTP_ROOT_PATH = "https://cp-new.easypay-card.com/API/";
  window.DOMAIN_TYPE = "1";
}

if (window.location.hostname == "user.jsc.com.jo") {
  window.MAIN_IMAGE = "_1";
  window.MODE = "2";
} else if (window.location.hostname == "users.gm-cards.com") {
  window.MAIN_IMAGE = "_9";
  window.MODE = "1";
} else if (window.location.hostname == "control.elite-card.net") {
  window.MAIN_IMAGE = "_2";
  window.MODE = "1";
} else if (window.location.hostname == "control.amg-card.com") {
  window.MAIN_IMAGE = "_3";
  window.MODE = "1";
} else {
  window.MODE = "1";
  window.MAIN_IMAGE = "";
}

// if(window.location.hostname == 'dev44.hoja-crm.com' || window.location.hostname == 'localhost'){
//     window.MAIN_IMAGE='_2'
// }else{
//     window.MAIN_IMAGE='_1'
// }

window.HTTP_SUP_PATH = "https://" + window.location.hostname + "/react/";
window.API_PERM_USER = window.HTTP_ROOT_PATH + "permUser.php";
window.API_PERM_USER_EXPORT = window.HTTP_ROOT_PATH + "permUserExport.php";
window.API_DAFTRA_API = window.HTTP_ROOT_PATH + "daftra_Api.php";

window.AUTH = "Basic dHVhOnR1YXR1YQ==";
// window.AUTH = 'Basic '+btoa('tua:tuatua')

// window.AUTH = 'Bearer dHVhOnR1YXR1YQ=='
// window.AUTH = 'Bearer '+btoa('tua:tuatua')
